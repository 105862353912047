import {
  addMonths,
  differenceInDays,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  endOfDay,
  format,
  formatDistanceStrict,
  isToday,
  isYesterday,
  parseISO,
  startOfDay,
  subHours,
} from 'date-fns';
import { format as formatTz, getTimezoneOffset } from 'date-fns-tz';

// output: 12/04/2024
export const formatDate = (date: Date | string): string => {
  return format(date, 'dd/MM/yyyy');
};

export const formatDateSpaced = (date: Date | string): string => {
  return format(date, 'dd / MM / yyyy');
};

export const formatFilterDate = (date: Date | string): string => {
  return format(date, 'yyyy-MM-dd');
};

// output: 12 April, 2024
export const formatDateToLongFormat = (date: Date | string): string => {
  return format(date, 'dd MMMM, yyyy');
};

// output: Date object with months added
export const addMonthsToDate = (date: Date | string, months: number): Date => {
  return addMonths(date, months);
};

// output: Sat, Apr 12, 2024
export const formatDateToDayMonthDateYear = (date: Date | string): string => {
  return format(new Date(date), 'EEE, MMM dd, yyyy');
};

// output: 10:30:45 AM
export const formatTime = (date: Date | string): string => {
  return format(date, 'h:mm:ss a');
};

// input: '2024-06-24 11:13:04' output: '2024-06-24T11:13:04Z'
export const convertDateStringToISO8601 = (dateStr: string) => {
  return dateStr.replace(' ', 'T') + 'Z';
};

// input: '2024-06-24 11:13:04' output: '23/06/2024 / 12:15:35 PM
export const formatToISO8601WithDateFns = (dateStr: string) => {
  const date = convertDateStringToISO8601(dateStr);
  const originalDate = new Date(date);

  return `${formatDate(originalDate)} / ${formatTime(originalDate)}`;
};

export const formatDateWithHours = (
  date: Date | string,
  includeHours: boolean
): string => {
  const formatString = includeHours ? 'dd/MM/yyyy hh:mm a' : 'dd/MM/yyyy';
  return format(new Date(date), formatString);
};

// output 22:00 • 04/04/2024
export const formatDateTimeWithSeparator = (
  date: Date | string,
  includeSeparator: boolean = true,
  timeFirst: boolean = true
): string => {
  const timePart = format(new Date(date), 'HH:mm');
  const datePart = format(new Date(date), 'dd/MM/yyyy');
  const separator = includeSeparator ? ' • ' : ' ';
  return timeFirst ? `${timePart}${separator}${datePart}` : `${datePart}${separator}${timePart}`;
};

export const formatHoursWithDate = (date: Date | string): string => {
  const formatString = 'hh:mm a dd/MM/yyyy';
  return format(new Date(date), formatString);
};

// output: 09:50 AM
export const formatHours = (date: Date | string): string => {
  return format(new Date(date), 'hh:mmaaa');
};

export const formatMessageDate = (date: Date | string): string => {
  const d = new Date(date);
  if (isToday(d)) {
    return 'Today';
  } else if (isYesterday(d)) {
    return 'Yesterday';
  } else {
    return format(d, 'EEE, MMM dd');
  }
};

// output: 12/04/2024 / 10:30:45 AM
export const formatDateTime = (date: Date | string): string =>
  `${formatDate(date)} / ${formatTime(date)}`;

//output: 14:00 23/04/2024
export const formatDateToHDMY = (dateStr: string) => {
  const parsedDate = parseISO(dateStr);
  const formattedDate = format(new Date(parsedDate), 'HH:mm dd/MM/yyyy');
  return formattedDate;
};

export const formatStartDate = (
  dateStr: string,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  const offset = getTimezoneOffset(timeZone) / 3600000;

  const dateWithOffset = subHours(startOfDay(new Date(dateStr)), offset);

  return formatTz(dateWithOffset, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", {
    timeZone: timeZone,
  });
};

export const formatEndDate = (
  dateStr: string,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  const offset = getTimezoneOffset(timeZone) / 3600000;

  const dateWithOffset = subHours(endOfDay(new Date(dateStr)), offset);

  return formatTz(dateWithOffset, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", {
    timeZone: timeZone,
  });
};

export const formatEndOfDay = (dateStr: string | Date) => {
  return endOfDay(dateStr);
};

// output: 13:30
export const formatTimeToHM = (date: Date | string): string => {
  return format(date, 'HH:mm');
};

// output: 12 / 04 / 2024
export const formatDateWithSpaces = (date: Date | string): string => {
  return format(date, 'dd / MM / yyyy');
};

// output: 1d 21h 19m
export const getTimeDifference = (date: string): string | null => {
  const targetDate = parseISO(date);
  const currentDate = new Date();
  if (differenceInMilliseconds(targetDate, currentDate) < 0) return null;

  const days = differenceInDays(targetDate, currentDate);
  const remainingHours = differenceInHours(targetDate, currentDate) % 24;
  const remainingMinutes = differenceInMinutes(targetDate, currentDate) % 60;

  // An extra minute is added to the remaining minutes to make it more accurate, because of the missing seconds
  return `${days ? `${days}d ` : ''}${remainingHours}h ${remainingMinutes + 1}m`;
};

export const formatToISO8601 = (date: Date) => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss");
};

export const addTimeToDate = (date: Date, hours: number) => {
  return new Date(date.getTime() + hours * 60 * 60 * 1000);
};

//INPUT 0.5 -> OUTPUT -> 30min
export const formatHoursToHM = (hours: number): string => {
  if (isNaN(hours)) {
    return '';
  }

  const hrs = Math.floor(hours);
  const mins = Math.round((hours - hrs) * 60);

  if (hrs === 0) {
    return `${mins} min`;
  }

  if (mins === 0) {
    return `${hrs} hour`;
  }

  return `${hrs} hour ${mins} min`;
};

export const formatForumCreatedAt = (date: string | Date): string => {
  const MINUTES_IN_HOUR = 60;
  const MINUTES_IN_DAY = 24 * MINUTES_IN_HOUR;
  const MINUTES_IN_TWO_DAYS = 2 * MINUTES_IN_DAY;
  const MINUTES_IN_WEEK = 7 * MINUTES_IN_DAY;

  const now = new Date();
  const createdDate = new Date(date);
  const diffInMinutes = Math.floor(
    (now.getTime() - createdDate.getTime()) / 60000
  );

  if (diffInMinutes < 1) {
    return 'Just now';
  } else if (diffInMinutes === 1) {
    return '1 minute ago';
  } else if (diffInMinutes < MINUTES_IN_HOUR) {
    return `${diffInMinutes} minutes ago`; // Output: 5 minutes ago
  } else if (diffInMinutes < MINUTES_IN_DAY) {
    return `${Math.floor(diffInMinutes / MINUTES_IN_HOUR)}h ago`; // Output: 5h ago
  } else if (diffInMinutes < MINUTES_IN_TWO_DAYS) {
    return 'Yesterday';
  } else if (diffInMinutes < MINUTES_IN_WEEK) {
    return `${Math.floor(diffInMinutes / MINUTES_IN_DAY)}d ago`; // Output: 5d ago
  } else {
    return formatDate(createdDate);
  }
};

export function timeElapsed(inactiveAt: string) {
  const pastTime = new Date(inactiveAt);
  const currentTime = new Date();

  if (pastTime > currentTime) {
    return "-";
  }

  return formatDistanceStrict(pastTime, currentTime, { unit: "minute" });
}
